import React from 'react';
import { Link } from 'react-router-dom';
import '../assets/ThankYou.css';
import HeroSection from "../components/Hero";
import ImpactSection from "../components/Impact";
import PricingSection from "../components/Pricing";
import FAQSection from "../components/FAQ";

const HomePage = ({isLoggedIn, onSignIn}) => {
    return (
        <div className="home">
            <HeroSection isLoggedIn={isLoggedIn} onSignIn={onSignIn} />
            <ImpactSection/>
            <PricingSection isLoggedIn={isLoggedIn} onSignIn={onSignIn} />
            <FAQSection/>
        </div>
    );
};

export default HomePage;