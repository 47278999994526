import ReactGA from 'react-ga4';

const TRACKING_ID = 'G-HQK7WFJ98P'; // Replace with your GA4 Measurement ID
ReactGA.initialize(TRACKING_ID);

export const trackPageView = (path) => {
    ReactGA.send({ hitType: 'pageview', page: path });
};

export const trackEvent = (category, action, label) => {
    ReactGA.event({
        category: category,
        action: action,
        label: label,
    });
};
