import React from 'react';
import { Page, Text, View, Document, StyleSheet, Font } from '@react-pdf/renderer';

// Register a custom font (optional)
Font.register({
    family: 'Open Sans',
    src: 'https://fonts.gstatic.com/s/opensans/v18/mem8YaGs126MiZpBA-UFVZ0e.ttf',
});

// Define styles
const styles = StyleSheet.create({
    page: {
        padding: 30,
        fontFamily: 'Open Sans',
    },
    header: {
        fontSize: 24,
        marginBottom: 10,
    },
    subHeader: {
        fontSize: 18,
        marginBottom: 5,
    },
    text: {
        fontSize: 12,
        marginBottom: 5,
    },
    skillsHeader: {
        fontSize: 12,
        marginBottom: 5,
    },
    skillsText: {
        fontSize: 10,
        marginTop: 15,
        marginBottom: 5,
    },
    divider: {
        marginVertical: 10,
    },
    sectionHeader: {
        fontSize: 16,
        marginBottom: 5,
        textTransform: 'uppercase',
        borderBottom: '1px solid #000',
    },
    experienceItem: {
        marginBottom: 10,
    },
    bulletPoint: {
        display: 'flex',
        flexDirection: 'row',
    },
    bulletText: {
        fontSize: 12,
        marginLeft: 10,
        marginRight: 10,
        marginBottom: 2,
    },
    bulletSymbol: {
        width: 10,
    },
});

const BulletPoint = ({ children }) => (
    <View style={styles.bulletPoint}>
        <Text style={styles.bulletSymbol}>•</Text>
        <Text style={styles.bulletText}>{children}</Text>
    </View>
);

const ResumePDF = ({ resume }) => {
    const resumeData = resume.getFieldsValue();
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View>
                    <Text style={styles.header}>{resumeData.personalInfo.fullName}</Text>
                    <Text style={styles.text}>
                        {resumeData.personalInfo.contacts.join(' | ')}
                    </Text>
                </View>

                <View style={styles.divider}></View>

                {resumeData.experiences && resumeData.experiences.length > 0 && (
                    <View>
                        <Text style={styles.sectionHeader}>Experience</Text>
                        {resumeData.experiences.map((experience, index) => (
                            <View key={index} style={styles.experienceItem}>
                                <Text style={styles.subHeader}>{experience.company}</Text>
                                <Text style={styles.text}>{experience.title} | {experience.dates}</Text>
                                <Text style={styles.bulletText}>{experience.text}</Text>
                                <Text style={styles.skillsText}><Text style={styles.skillsHeader}>Skills: </Text>{experience.skills}</Text>
                            </View>
                        ))}
                        <View style={styles.divider}></View>
                    </View>
                )}

                {resumeData.educations && resumeData.educations.length > 0 && (
                    <View>
                        <Text style={styles.sectionHeader}>Education</Text>
                        {resumeData.educations.map((education, index) => (
                            <View key={index}>
                                <Text style={styles.subHeader}>{education.school}</Text>
                                <Text style={styles.text}>{education.degree} | {education.major} | {education.dates}</Text>
                                <Text style={styles.text}>{education.text}</Text>
                            </View>
                        ))}
                        <View style={styles.divider}></View>
                    </View>
                )}

                {resumeData.certificates && resumeData.certificates.length > 0 && (
                    <View>
                        <Text style={styles.sectionHeader}>Certificates</Text>
                        {resumeData.certificates.map((certificate, index) => (
                            <View key={index}>
                                <Text style={styles.subHeader}>{certificate.name}</Text>
                                <Text style={styles.text}>{certificate.issuer} | {certificate.date}</Text>
                                <Text style={styles.text}>{certificate.text}</Text>
                            </View>
                        ))}
                        <View style={styles.divider}></View>
                    </View>
                )}

                {resumeData.projects && resumeData.projects.length > 0 && (
                    <View>
                        <Text style={styles.sectionHeader}>Projects</Text>
                        {resumeData.projects.map((project, index) => (
                            <View key={index}>
                                <Text style={styles.subHeader}>{project.name}</Text>
                                <Text style={styles.text}>{project.description}</Text>
                            </View>
                        ))}
                        <View style={styles.divider}></View>
                    </View>
                )}
            </Page>
        </Document>
    );
};

export default ResumePDF;
