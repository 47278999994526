// src/components/HeroSection.js
import React from 'react';
import '../assets/Hero.css';
import { FaArrowRight } from 'react-icons/fa'
import axiosInstance from "../utils/axios";
import {trackEvent} from "../utils/analytics";

const HeroSection = ({isLoggedIn, onSignIn}) => {
    const topUp = async () => {
        trackEvent('User', 'TopUp', 'TopUp Button Clicked');
        const response = await axiosInstance.post("/payment/checkout");
        window.location.href = response.data.redirectUrl;
    };

    return (
        <section className="hero">
            <div className="container">
                <div className="hero-content">
                    <h1 className="hero-title">Say goodbye to resume struggles!</h1>
                    <p className="hero-summary">Our friendly AI assistant is here to help you craft personalized resumes and cover letters for your dream job postings. Stand out from the crowd and land your next opportunity with ease.</p>
                    {!isLoggedIn ? (
                        <button className="cta-button" onClick={onSignIn}>Get Started <FaArrowRight/></button>
                    ) : (
                        <button className="cta-top-up-button" onClick={topUp}>Add Credits Now <FaArrowRight/></button>
                    )}
                </div>
            </div>
        </section>
    );
}

export default HeroSection;
