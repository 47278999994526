import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {Form, Input, Button, Checkbox, Divider, Typography, notification, Spin, Select, Alert, Modal} from 'antd';
import axiosInstance from '../utils/axios';
import '../assets/ApplicationDetail.css';
import ReactPDF, {PDFDownloadLink, Text, View} from '@react-pdf/renderer';
import ResumePDF from "../pages/ResumePDF";
import {trackEvent} from "../utils/analytics";


const { Title } = Typography;
const { Option } = Select;
const { TextArea } = Input;

const ApplicationDetail = () => {
    const { id } = useParams();
    const [form] = Form.useForm();
    const [application, setApplication] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(() => {
        const fetchApplication = async () => {
            try {
                const response = await axiosInstance.get(`/application/${id}`); // Adjust the endpoint as necessary
                setApplication(response.data);
                const formData = {
                    ...(response.data.resume),
                    job: response.data.job,
                    coverLetter: response.data.coverLetter,
                }
                form.setFieldsValue(formData);
            } catch (error) {
                notification.error({ message: 'Failed to fetch application details' });
            } finally {
                setLoading(false);
            }
        };

        fetchApplication();
    }, [id]);

    if (loading) {
        return <Spin size="large" className="loading-spinner" />;
    }

    if (!application) {
        notification.error({ message: 'Application not found' });
    }

    const handleSubmit = async () => {
        trackEvent('User', 'PDFDownload', 'PDF Downloaded');
        // notification.error({ message: 'Handle Submit' });
    }

    const handleSave = async () => {
        try {
            trackEvent('User', 'UpdateApplication', 'Application Updated');
            const values = form.getFieldsValue();
            const payload = {
                resume: {
                    personalInfo: values.personalInfo,
                    experiences: values.experiences,
                    educations: values.educations,
                    certificates: values.certificates,
                    projects: values.projects,
                },
                coverLetter: values.coverLetter,
            };

            const response = await axiosInstance.put(`/application/${id}`, payload);
            if (response.status === 200) {
                notification.success({ message: 'Your changes saved successfully' });
            } else {
                notification.error({ message: 'An error occurred during save' });
            }
        } catch (e) {
            notification.error({ message: 'An error occurred during save' });
        }
    }

    const showModal = () => {
        trackEvent('User', 'RefundModal', 'Refund Modal Viewed');
        setIsModalVisible(true);
    };

    const handleOk = async () => {
        try {
            trackEvent('User', 'RefundRequest', 'Refund Request Created');
            const response = await axiosInstance.put(`/application/refund/${id}`);
            if (response.status === 200 && response.data.modifiedCount > 0) {
                application.status = 100;
                setApplication(application);
                notification.success({ message: 'Credit refund request sent. We will review your request as soon as possible and send you the result via email.' });
            } else {
                notification.error({ message: 'An error occurred. You can always send an email to help@careergenius.app or try this button later' });
            }
        } catch (e) {
            notification.error({ message: 'An error occurred. You can always send an email to help@careergenius.app or try this button later' });
        }
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <div className="new-resume">
            <Form form={form} layout="vertical">
                <div className="detail-form-header">
                    <Title level={2}>Application {id.slice(-5)}</Title>
                    {!application.status && (
                        <Button className="refund-btn" type="text" onClick={showModal}>Ask Credit Refund</Button>
                    )}
                    {application.status && (
                        <Button className="refund-btn" type="text" disabled>Review is in progress for refund</Button>
                    )}
                </div>
                <Alert
                    description="You can amend your AI-created resume as you wish."
                    type="info"
                    showIcon
                    style={{ marginTop: 20 }}
                />
                <Divider />

                <Form.Item label="Full Name" name={["personalInfo", "fullName"]}>
                    <Input placeholder="Full Name" />
                </Form.Item>

                <Form.List name={["personalInfo", "contacts"]}>
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map((field, index) => {
                                const { key, ...restField } = field;
                                return (
                                    <div key={key} className={`contact-item ${index % 2 === 0 ? 'even' : 'odd'}`}>
                                        <Button type="danger" className="remove-btn" onClick={() => remove(field.name)}>X</Button>
                                        <Form.Item
                                            {...restField}
                                            label={`Contact ${index + 1}`}
                                            name={[field.name]}
                                            fieldKey={[field.fieldKey]}
                                            rules={[{ required: true, message: 'Missing contact' }]}
                                        >
                                            <Input placeholder={`Contact ${index + 1}`} />
                                        </Form.Item>
                                    </div>
                                );
                            })}
                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} block>
                                    Add Contact
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>

                <Divider />

                <Title level={3}>Experiences</Title>
                <Form.List name="experiences">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map((field, index) => {
                                const { key, ...restField } = field;
                                return (
                                    <div key={key} className={`experience-item ${index % 2 === 0 ? 'even' : 'odd'}`}>
                                        <Button type="danger" className="remove-btn" onClick={() => remove(field.name)}>X</Button>
                                        <Form.Item
                                            {...restField}
                                            label="Company"
                                            name={[field.name, 'company']}
                                            fieldKey={[field.fieldKey, 'company']}
                                            rules={[{ required: true, message: 'Missing company' }]}
                                        >
                                            <Input placeholder="Company" />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            label="Title"
                                            name={[field.name, 'title']}
                                            fieldKey={[field.fieldKey, 'title']}
                                            rules={[{ required: true, message: 'Missing title' }]}
                                        >
                                            <Input placeholder="Title" />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            label="Dates"
                                            name={[field.name, 'dates']}
                                            fieldKey={[field.fieldKey, 'dates']}
                                            rules={[{ required: true, message: 'Missing dates' }]}
                                        >
                                            <Input placeholder="Dates" />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            label="Description"
                                            name={[field.name, 'text']}
                                            fieldKey={[field.fieldKey, 'text']}
                                            rules={[{ required: true, message: 'Missing description' }]}
                                        >
                                            <TextArea placeholder="Description" />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            label="Skills"
                                            name={[field.name, 'skills']}
                                            fieldKey={[field.fieldKey, 'skills']}
                                            rules={[{ required: true, message: 'Missing skills' }]}
                                        >
                                            <Input placeholder="Skills" />
                                        </Form.Item>
                                    </div>
                                );
                            })}
                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} block>
                                    Add Experience
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>

                <Divider />

                <Title level={3}>Education</Title>
                <Form.List name="educations">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map((field, index) => {
                                const { key, ...restField } = field;
                                return (
                                    <div key={key} className={`education-item ${index % 2 === 0 ? 'even' : 'odd'}`}>
                                        <Button type="danger" className="remove-btn" onClick={() => remove(field.name)}>X</Button>
                                        <Form.Item
                                            {...restField}
                                            label="School"
                                            name={[field.name, 'school']}
                                            fieldKey={[field.fieldKey, 'school']}
                                            rules={[{ required: true, message: 'Missing school' }]}
                                        >
                                            <Input placeholder="School" />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            label="Degree"
                                            name={[field.name, 'degree']}
                                            fieldKey={[field.fieldKey, 'degree']}
                                            rules={[{ required: true, message: 'Missing degree' }]}
                                        >
                                            <Input placeholder="Degree" />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            label="Dates"
                                            name={[field.name, 'dates']}
                                            fieldKey={[field.fieldKey, 'dates']}
                                            rules={[{ required: true, message: 'Missing dates' }]}
                                        >
                                            <Input placeholder="Dates" />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            label="Major"
                                            name={[field.name, 'major']}
                                            fieldKey={[field.fieldKey, 'major']}
                                            rules={[{ required: true, message: 'Missing major' }]}
                                        >
                                            <Input placeholder="Major" />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            label="Description"
                                            name={[field.name, 'text']}
                                            fieldKey={[field.fieldKey, 'text']}
                                        >
                                            <TextArea placeholder="Description" />
                                        </Form.Item>
                                    </div>
                                );
                            })}
                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} block>
                                    Add Education
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>

                <Divider />

                <Title level={3}>Certificates</Title>
                <Form.List name="certificates">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map((field, index) => {
                                const { key, ...restField } = field;
                                return (
                                    <div key={key} className={`certificate-item ${index % 2 === 0 ? 'even' : 'odd'}`}>
                                        <Button type="danger" className="remove-btn" onClick={() => remove(field.name)}>X</Button>
                                        <Form.Item
                                            {...restField}
                                            label="Certificate Name"
                                            name={[field.name, 'name']}
                                            fieldKey={[field.fieldKey, 'name']}
                                            rules={[{ required: true, message: 'Missing certificate name' }]}
                                        >
                                            <Input placeholder="Certificate Name" />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            label="Issuer"
                                            name={[field.name, 'issuer']}
                                            fieldKey={[field.fieldKey, 'issuer']}
                                            rules={[{ required: true, message: 'Missing issuer' }]}
                                        >
                                            <Input placeholder="Issuer" />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            label="Date"
                                            name={[field.name, 'date']}
                                            fieldKey={[field.fieldKey, 'date']}
                                            rules={[{ required: true, message: 'Missing date' }]}
                                        >
                                            <Input placeholder="Date" />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            label="Description"
                                            name={[field.name, 'description']}
                                            fieldKey={[field.fieldKey, 'description']}
                                        >
                                            <TextArea placeholder="Description" />
                                        </Form.Item>
                                    </div>
                                );
                            })}
                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} block>
                                    Add Certificate
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>

                <Divider />

                <Title level={3}>Projects</Title>
                <Form.List name="projects">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map((field, index) => {
                                const { key, ...restField } = field;
                                return (
                                    <div key={key} className={`project-item ${index % 2 === 0 ? 'even' : 'odd'}`}>
                                        <Button type="danger" className="remove-btn" onClick={() => remove(field.name)}>X</Button>
                                        <Form.Item
                                            {...restField}
                                            label="Project Name"
                                            name={[field.name, 'name']}
                                            fieldKey={[field.fieldKey, 'name']}
                                            rules={[{ required: true, message: 'Missing project name' }]}
                                        >
                                            <Input placeholder="Project Name" />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            label="Description"
                                            name={[field.name, 'description']}
                                            fieldKey={[field.fieldKey, 'description']}
                                            rules={[{ required: true, message: 'Missing description' }]}
                                        >
                                            <TextArea placeholder="Description" />
                                        </Form.Item>
                                    </div>
                                );
                            })}
                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} block>
                                    Add Project
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>

                <Divider />

                <Form.Item label="Cover Letter" name="coverLetter">
                    <TextArea placeholder="Your cover letter for this job" />
                </Form.Item>

                <Divider />

                <Form.Item label="Job" name="job" rules={[{ required: true, message: 'Missing Job Description' }]}>
                    <TextArea placeholder="Enter job posting here..." readOnly/>
                </Form.Item>

                <Divider />

                <Form.Item className="form-button-group">
                    <Button type="text" onClick={handleSave}>Save</Button>
                    <Button type="primary" onClick={handleSubmit}>
                        <PDFDownloadLink type="primary" document={<ResumePDF resume={form} />} fileName="resume.pdf">
                            {({ blob, url, loading, error }) =>
                                loading ? 'Loading document...' : 'Download Resume'
                            }
                        </PDFDownloadLink>
                    </Button>

                </Form.Item>
            </Form>
            <Modal
                title="Credit Refund Request"
                open={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Ask Refund"
            >
                <p>We know that sometimes AI can produce irrelevant results. If you encounter such a case, you can always request a refund.</p>
            </Modal>
        </div>
    );
};

export default ApplicationDetail;
