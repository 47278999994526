import axios from 'axios';

const instance = axios.create({
    // baseURL: 'http://localhost:8000', // Your base URL here
    baseURL: 'https://api.careergenius.app'
});

// Add a request interceptor
instance.interceptors.request.use(
    (config) => {
        // Get the access token from local storage
        const accessToken = localStorage.getItem('accessToken');
        // Add the authorization header with the bearer token
        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (response) => response,
    async (error) => {
        // Handle 401 or 403 errors by refreshing the access token
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
            try {
                const newAccessToken = await refreshTokenFlow();
                // Retry the original request with the new access token
                error.config.headers.Authorization = `Bearer ${newAccessToken}`;
                return instance.request(error.config);
            } catch (refreshError) {
                // Handle refresh token error (e.g., log out user, display error message)
                console.error('Error refreshing token:', refreshError);
                return Promise.reject(refreshError);
            }
        }
        // Handle other errors (e.g., log out user, display error message)
        console.error('Error:', error);
        return Promise.reject(error);
    }
);

const refreshTokenFlow = async () => {
    try {
        const refreshToken = localStorage.getItem('refreshToken');
        // Send a request to your backend to refresh the token
        const response = await instance.post('/user/refresh-token', {
            refreshToken
        });

        // Update the access token with the new token received from the backend
        localStorage.setItem('accessToken', response.data.token);
        return response.data.token; // Return the new access token
    } catch (error) {
        console.error('Error refreshing token:', error);
        throw error; // Rethrow the error to be handled by the caller
    }
};

export default instance;