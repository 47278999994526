import React, { useEffect, useState } from 'react';
import {Form, Input, Button, Select, Checkbox, Divider, Typography, notification, Spin} from 'antd';
import axiosInstance from "../utils/axios";
import { useNavigate } from "react-router-dom";
import '../assets/NewResume.css';
import {trackEvent} from "../utils/analytics";

const { Title } = Typography;
const { Option } = Select;
const { TextArea } = Input;

const NewResume = ({ fetchUserCredits }) => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [experiences, setExperiences] = useState([{ company: '', title: '', dates: '', text: '', skills: '' }]);
    const [educations, setEducations] = useState([{ school: '', degree: '', dates: '', major: '', text: '' }]);
    const [certificates, setCertificates] = useState([{ name: '', issuer: '', date: '', description: '' }]);
    const [projects, setProjects] = useState([{ name: '', description: '' }]);
    const [contacts, setContacts] = useState(['']);
    const [savedInfos, setSavedInfos] = useState([]);
    const [selectedInfo, setSelectedInfo] = useState('');
    const [createOptions, setCreateOptions] = useState({ resume: false, coverLetter: false });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.get('/info'); // Replace with your API endpoint
                setSavedInfos(response.data);
            } catch (error) {
                notification.error({ message: 'Failed to load saved details' });
            }
        };

        fetchData();
    }, []);

    const handleDropdownChange = (value) => {
        setSelectedInfo(value);
        const selectedInfoObj = savedInfos.find((info) => info._id === value);

        form.setFieldsValue({
            fullName: selectedInfoObj.personalInfo.fullName,
            contacts: selectedInfoObj.personalInfo.contacts,
            experiences: selectedInfoObj.experiences,
            educations: selectedInfoObj.educations,
            certificates: selectedInfoObj.certificates,
            projects: selectedInfoObj.projects,
            alias: selectedInfoObj.alias,
        });

        setContacts(selectedInfoObj.personalInfo.contacts);
        setExperiences(selectedInfoObj.experiences);
        setEducations(selectedInfoObj.educations);
        setCertificates(selectedInfoObj.certificates);
        setProjects(selectedInfoObj.projects);
    };

    const handleSave = async () => {
        trackEvent('User', 'SaveInfo', 'Save Info Button clicked');
        try {
            setLoading(true);
            const values = form.getFieldsValue();
            const payload = {
                personalInfo: {
                    fullName: values.fullName,
                    contacts: values.contacts
                },
                experiences: values.experiences,
                educations: values.educations,
                certificates: values.certificates,
                projects: values.projects,
                alias: values.alias
            };
            let response;

            if (selectedInfo) {
                response = await axiosInstance.put(`/info/${selectedInfo}`, payload);
            } else {
                response = await axiosInstance.post("/info", payload);
            }

            if (response.status === 200) {
                notification.success({ message: 'The details saved for further usages' });
            } else {
                notification.error({ message: 'An error occurred during save' });
            }
        } catch (error) {
            notification.error({ message: 'An error occurred during save' });
        } finally {
            setLoading(false);
        }
    };

    const handleSaveAs = async () => {
        trackEvent('User', 'SaveInfoAs', 'Save As Info Button clicked');
        try {
            setLoading(true);
            const values = form.getFieldsValue();
            const payload = {
                personalInfo: {
                    fullName: values.fullName,
                    contacts: values.contacts
                },
                experiences: values.experiences,
                educations: values.educations,
                certificates: values.certificates,
                projects: values.projects,
                alias: values.alias
            };
            const response = await axiosInstance.post("/info", payload);

            if (response.status === 200) {
                notification.success({ message: 'The details saved for further usages' });
            } else {
                notification.error({ message: 'An error occurred during save' });
            }
        } catch (error) {
            notification.error({ message: 'An error occurred during save' });
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = async () => {
        trackEvent('User', 'Generate', 'Generate Resume & CoverLetter Button clicked');
        if (!createOptions.coverLetter && !createOptions.resume) {
            notification.error({ message: 'At least one of Cover Letter or Resume must be checked.' });
            return;
        }
        setLoading(true);
        try {
            const values = form.getFieldsValue();
            const payload = {
                resume: {
                    personalInfo: {
                        fullName: values.fullName,
                        contacts: values.contacts
                    },
                    experiences: values.experiences,
                    educations: values.educations,
                    certificates: values.certificates,
                },
                job: values.job,
                createOptions
            };
            const response = await axiosInstance.post("/resume", payload);

            if (response.status === 200) {
                if (createOptions.resume) {
                    notification.success({ message: 'Your super duper resume is crafted and sent to your email address' });
                }
                if (createOptions.coverLetter) {
                    notification.success({ message: 'The cover letter is crafted. Now it\'s time to get hired' });
                }
                fetchUserCredits();
                navigate(`/application/${response.data.application}`);
            } else {
                notification.error({ message: 'An error occurred during crafting' });
            }
        } catch (error) {
            notification.error({ message: 'An error occurred during crafting' });
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <Spin size="large" className="loading-spinner" />;
    }

    return (
        <div className="new-resume">
            <Form form={form} layout="vertical">
                <Title level={2}>Create New Resume</Title>
                <Form.Item label="Saved Details:" name="savedInfo">
                    <Select placeholder="Saved Details" onChange={handleDropdownChange}>
                        {savedInfos.map((option) => (
                            <Option key={option._id} value={option._id}>
                                {option.alias}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>

                <Divider />

                <Form.Item label="Full Name" name="fullName">
                    <Input placeholder="Full Name" />
                </Form.Item>

                <Form.List name="contacts">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map((field, index) => {
                                const { key, ...restField } = field;
                                return (
                                    <div key={key} className={`contact-item ${index % 2 === 0 ? 'even' : 'odd'}`}>
                                        <Button type="danger" className="remove-btn" onClick={() => remove(field.name)}>X</Button>
                                        <Form.Item
                                            {...restField}
                                            label={`Contact ${index + 1}`}
                                            name={[field.name]}
                                            fieldKey={[field.fieldKey]}
                                            rules={[{ required: true, message: 'Missing contact' }]}
                                        >
                                            <Input placeholder={`Contact ${index + 1}`} />
                                        </Form.Item>
                                    </div>
                                );
                            })}
                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} block>
                                    Add Contact
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>

                <Divider />

                <Title level={3}>Experiences</Title>
                <Form.List name="experiences">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map((field, index) => {
                                const { key, ...restField } = field;
                                return (
                                    <div key={key} className={`experience-item ${index % 2 === 0 ? 'even' : 'odd'}`}>
                                        <Button type="danger" className="remove-btn" onClick={() => remove(field.name)}>X</Button>
                                        <Form.Item
                                            {...restField}
                                            label="Company"
                                            name={[field.name, 'company']}
                                            fieldKey={[field.fieldKey, 'company']}
                                            rules={[{ required: true, message: 'Missing company' }]}
                                        >
                                            <Input placeholder="Company" />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            label="Title"
                                            name={[field.name, 'title']}
                                            fieldKey={[field.fieldKey, 'title']}
                                            rules={[{ required: true, message: 'Missing title' }]}
                                        >
                                            <Input placeholder="Title" />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            label="Dates"
                                            name={[field.name, 'dates']}
                                            fieldKey={[field.fieldKey, 'dates']}
                                            rules={[{ required: true, message: 'Missing dates' }]}
                                        >
                                            <Input placeholder="Dates" />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            label="Description"
                                            name={[field.name, 'text']}
                                            fieldKey={[field.fieldKey, 'text']}
                                            rules={[{ required: true, message: 'Missing description' }]}
                                        >
                                            <TextArea placeholder="Description" />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            label="Skills"
                                            name={[field.name, 'skills']}
                                            fieldKey={[field.fieldKey, 'skills']}
                                            rules={[{ required: true, message: 'Missing skills' }]}
                                        >
                                            <Input placeholder="Skills" />
                                        </Form.Item>
                                    </div>
                                );
                            })}
                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} block>
                                    Add Experience
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>

                <Divider />

                <Title level={3}>Education</Title>
                <Form.List name="educations">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map((field, index) => {
                                const { key, ...restField } = field;
                                return (
                                    <div key={key} className={`education-item ${index % 2 === 0 ? 'even' : 'odd'}`}>
                                        <Button type="danger" className="remove-btn" onClick={() => remove(field.name)}>X</Button>
                                        <Form.Item
                                            {...restField}
                                            label="School"
                                            name={[field.name, 'school']}
                                            fieldKey={[field.fieldKey, 'school']}
                                            rules={[{ required: true, message: 'Missing school' }]}
                                        >
                                            <Input placeholder="School" />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            label="Degree"
                                            name={[field.name, 'degree']}
                                            fieldKey={[field.fieldKey, 'degree']}
                                            rules={[{ required: true, message: 'Missing degree' }]}
                                        >
                                            <Input placeholder="Degree" />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            label="Dates"
                                            name={[field.name, 'dates']}
                                            fieldKey={[field.fieldKey, 'dates']}
                                            rules={[{ required: true, message: 'Missing dates' }]}
                                        >
                                            <Input placeholder="Dates" />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            label="Major"
                                            name={[field.name, 'major']}
                                            fieldKey={[field.fieldKey, 'major']}
                                            rules={[{ required: true, message: 'Missing major' }]}
                                        >
                                            <Input placeholder="Major" />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            label="Description"
                                            name={[field.name, 'text']}
                                            fieldKey={[field.fieldKey, 'text']}
                                        >
                                            <TextArea placeholder="Description" />
                                        </Form.Item>
                                    </div>
                                );
                            })}
                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} block>
                                    Add Education
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>

                <Divider />

                <Title level={3}>Certificates</Title>
                <Form.List name="certificates">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map((field, index) => {
                                const { key, ...restField } = field;
                                return (
                                    <div key={key} className={`certificate-item ${index % 2 === 0 ? 'even' : 'odd'}`}>
                                        <Button type="danger" className="remove-btn" onClick={() => remove(field.name)}>X</Button>
                                        <Form.Item
                                            {...restField}
                                            label="Certificate Name"
                                            name={[field.name, 'name']}
                                            fieldKey={[field.fieldKey, 'name']}
                                            rules={[{ required: true, message: 'Missing certificate name' }]}
                                        >
                                            <Input placeholder="Certificate Name" />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            label="Issuer"
                                            name={[field.name, 'issuer']}
                                            fieldKey={[field.fieldKey, 'issuer']}
                                            rules={[{ required: true, message: 'Missing issuer' }]}
                                        >
                                            <Input placeholder="Issuer" />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            label="Date"
                                            name={[field.name, 'date']}
                                            fieldKey={[field.fieldKey, 'date']}
                                            rules={[{ required: true, message: 'Missing date' }]}
                                        >
                                            <Input placeholder="Date" />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            label="Description"
                                            name={[field.name, 'description']}
                                            fieldKey={[field.fieldKey, 'description']}
                                        >
                                            <TextArea placeholder="Description" />
                                        </Form.Item>
                                    </div>
                                );
                            })}
                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} block>
                                    Add Certificate
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>

                <Divider />

                <Title level={3}>Projects</Title>
                <Form.List name="projects">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map((field, index) => {
                                const { key, ...restField } = field;
                                return (
                                    <div key={key} className={`project-item ${index % 2 === 0 ? 'even' : 'odd'}`}>
                                        <Button type="danger" className="remove-btn" onClick={() => remove(field.name)}>X</Button>
                                        <Form.Item
                                            {...restField}
                                            label="Project Name"
                                            name={[field.name, 'name']}
                                            fieldKey={[field.fieldKey, 'name']}
                                            rules={[{ required: true, message: 'Missing project name' }]}
                                        >
                                            <Input placeholder="Project Name" />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            label="Description"
                                            name={[field.name, 'description']}
                                            fieldKey={[field.fieldKey, 'description']}
                                            rules={[{ required: true, message: 'Missing description' }]}
                                        >
                                            <TextArea placeholder="Description" />
                                        </Form.Item>
                                    </div>
                                );
                            })}
                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} block>
                                    Add Project
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>

                <Divider />

                <Form.Item label="Save Info" name="alias">
                    <Input placeholder="Save Details For Further Usage" />
                </Form.Item>
                <Form.Item className="detail-save-button-group">
                    <Button className="detail-save-button" type="default" onClick={handleSave}>Save</Button>
                    <Button className="detail-save-as-button" type="default" onClick={handleSaveAs}>Save As</Button>
                </Form.Item>

                <Divider />

                <Form.Item label="Job" name="job" rules={[{ required: true, message: 'Missing Job Description' }]}>
                    <TextArea placeholder="Enter job posting here..." />
                </Form.Item>

                <Divider />

                <Form.Item className="form-button-group" rules={[
                    {
                        validator: (_, value) => {
                            if (createOptions.coverLetter || createOptions.resume) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('At least one of Cover Letter or Resume must be checked.'));
                        },
                    },
                ]}>
                    <Checkbox
                        checked={createOptions.coverLetter}
                        onChange={(e) => setCreateOptions({ ...createOptions, coverLetter: e.target.checked })}
                    >
                        Cover Letter
                    </Checkbox>
                    <Checkbox
                        checked={createOptions.resume}
                        onChange={(e) => setCreateOptions({ ...createOptions, resume: e.target.checked })}
                    >
                        Resume
                    </Checkbox>

                    <Button type="primary" onClick={handleSubmit}>Generate</Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default NewResume;
