import React from 'react';
import '../assets/Impact.css';
import {FaCheckCircle, FaTimesCircle} from "react-icons/fa";
import {AiOutlineExclamationCircle} from "react-icons/ai";

const ImpactSection = () => {
    return (
        <section className="impact">
            <div className="container">
                <div className="impact-content">
                    <h2 className="impact-title">Unlock Your Resume's Potential</h2>
                    <p className="impact-description">Did you know that up to 95% of resumes get rejected by Applicant Tracking Systems (ATS)? Each job description has its unique scoring model, making it challenging to pass through. Our solution helps tailor your resume to beat ATS and get noticed by recruiters.</p>
                </div>
            </div>
        </section>
    );
}

export default ImpactSection;
