import React from 'react';
import '../assets/ToS.css';

const ToS = () => {

    return (
        <div className="terms-container">
            <h1>Terms of Service</h1>
            <p className="last-updated">Last Updated: June 20, 2024</p>

            <h2>Welcome to Career Genius!</h2>
            <p>
                These Terms of Service ("Terms") govern your use of the Career Genius website at
                <a href="https://careergenius.app"> https://careergenius.app</a> ("Website") and the services provided by Career Geniues.
                By using our Website and services, you agree to these Terms.
            </p>

            <h2>Description of Career Genius</h2>
            <p>
                Career Genius is a platform that helps users create resumes and cover letters for any job advertisements.
                Users can purchase credits and use them to create these documents.
            </p>

            <h2>Ownership and Usage Rights</h2>
            <p>
                When you purchase credits from Career Genius, you gain the right to use our service to create resumes and cover letters.
                You own the documents you create but do not have the right to resell them. All purchases are final, and no refunds will be
                provided except as required by law.
            </p>

            <h2>User Data and Privacy</h2>
            <p>
                We collect and store user data, including name, email, and payment information, as necessary to provide our services.
                For details on how we handle your data, please refer to our
                <a href="https://careergenius.app/privacy-policy"> Privacy Policy</a>.
            </p>

            <h2>Non-Personal Data Collection</h2>
            <p>
                We use web cookies to collect non-personal data for the purpose of improving our services and user experience.
            </p>

            <h2>Governing Law</h2>
            <p>
                These Terms are governed by the laws of Türkiye.
            </p>

            <h2>Updates to the Terms</h2>
            <p>
                We may update these Terms from time to time. Users will be notified of any changes via email.
            </p>

            <p>
                For any questions or concerns regarding these Terms of Service, please contact us at <a href="mailto:help@careergenius.app">help@careergenius.app</a>.
            </p>

            <p>
                Thank you for using Career Genius!
            </p>
        </div>
    );
};

export default ToS;
