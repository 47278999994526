import React, { useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import '../assets/Header.css';
import { FaArrowRight, FaPlus } from 'react-icons/fa';
import axiosInstance from "../utils/axios";
import { useNavigate } from "react-router-dom";
import logo from "../assets/images/logo.png";
import {trackEvent} from "../utils/analytics";
import { jwtDecode } from "jwt-decode";

const Header = ({ isLoggedIn, userCredits, onSignIn, onSignOut, userProfile }) => {
    const navigate = useNavigate();
    const [dropdownVisible, setDropdownVisible] = useState(false);

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };

    const handleDropdownItemClick = () => {
        setDropdownVisible(false);
    };

    const topUp = async () => {
        const token = localStorage.getItem('accessToken');
        const {sub: userId} = jwtDecode(token);
        trackEvent('User', 'Click', 'Top-up')
        window.location.href = `https://careergenius.lemonsqueezy.com/buy/97718b30-8d3b-4001-8c72-0457cfa03f98?media=0&discount=0&checkout[custom][user_id]=${userId}`
        // const response = await axiosInstance.post("/payment/checkout");
        // window.location.href = response.data.redirectUrl;
    };

    const newResumeAction = () => {
        navigate('new');
    };

    const goToApplications = () => {
        navigate('applications');
    };

    const getInitials = (name) => {
        const [firstName, lastName] = name.split(' ');
        return firstName.charAt(0) + lastName.charAt(0);
    };

    return (
        <header className="header">
            <div className="logo">
                <Link to="/">
                    <img src={logo} alt="CareerGenius" />
                </Link>
            </div>
            <nav className="menu">
                <ul>
                    <li><Link to="/#pricing" smooth>Pricing</Link></li>
                    <li><Link to="/#faq" smooth>FAQ</Link></li>
                </ul>
            </nav>
            <div className="header-right">
                {isLoggedIn ? (
                    <div className="user-info">
                        <div className="credits-box">
                            <span>Credits: {userCredits}</span>
                            <button className="top-up-btn" onClick={topUp}><FaPlus /></button>
                        </div>
                        <div className="create-new-button">
                            <button onClick={newResumeAction}>Create New</button>
                        </div>
                        <div className="profile-menu">
                            <button onClick={toggleDropdown}>
                                {userProfile.image ? (
                                    <img src={userProfile.image} alt="Profile" className="profile-image" />
                                ) : (
                                    <span className="profile-initials">{getInitials(userProfile.name)}</span>
                                )}
                            </button>
                            {dropdownVisible && (
                                <div className="dropdown">
                                    <button onClick={() => { handleDropdownItemClick(); goToApplications(); }}>Applications</button>
                                    <button className="signout-btn" onClick={() => { handleDropdownItemClick(); onSignOut(); }}>Sign Out</button>
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    <div className="signin-btn">
                        <button onClick={onSignIn}>Sign In</button>
                    </div>
                )}
            </div>
        </header>
    );
};

export default Header;
