import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import axiosInstance from '../utils/axios';
import '../assets/Applications.css';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';

const Applications = () => {
    const [applications, setApplications] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchApplications = async () => {
            try {
                const response = await axiosInstance.get('/application'); // Adjust the endpoint as necessary
                setApplications(response.data);
            } catch (error) {
                console.error('Failed to fetch applications', error);
            } finally {
                setLoading(false);
            }
        };

        fetchApplications();
    }, []);

    const handleIdClick = (id) => {
        navigate(`/application/${id}`); // Adjust the path as needed
    };

    const columns = [
        {
            title: 'ApplicationDetail ID',
            dataIndex: '_id',
            key: '_id',
            render: (text) => <a onClick={() => handleIdClick(text)} style={{ color: '#1890ff', cursor: 'pointer' }}>
                {text.slice(-5)}
            </a>
        },
        {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text) => moment.utc(text).local().format('YYYY-MM-DD HH:mm:ss')
        },
    ];

    return (
        <div className="applications-page">
            <h2>Applications</h2>
            <Table
                columns={columns}
                dataSource={applications}
                loading={loading}
                rowKey="_id"
            />
        </div>
    );
};

export default Applications;
