// PrivateRoute.js
import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import { useAuth } from '../utils/AuthContext';

const PrivateRoute = ({ path, element }) => {
    const { isLoggedIn } = useAuth();

    console.log('isLoggedInPrivate', isLoggedIn)
    return isLoggedIn ? (
        element
    ) : (
        <Navigate to="/" replace />
    );
};

export default PrivateRoute;
