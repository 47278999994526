// src/App.js
import React, {useContext, useEffect, useState} from 'react';
import {BrowserRouter as Router, Route, Routes, useLocation} from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import {useGoogleLogin} from "@react-oauth/google";
import axiosInstance from "./utils/axios";
import ThankYouPage from "./pages/ThankYou";
import HomePage from "./pages/Home";
import NewResume from "./components/NewResume";
import PrivateRoute from "./components/PrivateRoute";
import {AuthProvider, useAuth} from "./utils/AuthContext";
import ToS from "./components/ToS";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Applications from "./components/Applications";
import ApplicationDetail from "./components/ApplicationDetail";
import {trackEvent, trackPageView} from "./utils/analytics";
import {Helmet} from "react-helmet";

const usePageTracking = () => {
    const location = useLocation();

    useEffect(() => {
        trackPageView(location.pathname + location.search);
    }, [location]);
};

function App() {
    usePageTracking();
    const { isLoggedIn, setIsLoggedIn } = useAuth()
    const [userCredits, setUserCredits] = useState(0);
    const userProfile = {
        name: "Huseyin Ergon",
        image: null
    }

    const fetchUserCredits = async () => {
        try {
            const response = await axiosInstance.get('/user/credits');
            setUserCredits(response.data.credits);
        } catch (error) {
            console.error('Error fetching user credits:', error);
        }
    };
    useEffect(() => {
        // Check if access token exists in local storage
        const accessToken = localStorage.getItem('accessToken');
        if (isLoggedIn) {
            fetchUserCredits();
        } else {
            setUserCredits(0);
        }
    }, []);

    const handleSignIn = useGoogleLogin({
        onSuccess: async (codeResponse) => {
            const response = await axiosInstance.post('/user/google-sign-in', {
                code: codeResponse.code
            });

            localStorage.setItem('accessToken', response.data.token);
            localStorage.setItem('refreshToken', response.data.refreshToken);
            setIsLoggedIn(true);
            fetchUserCredits();
            trackEvent('User', 'Sign In', 'Google Sign In');
        },
        flow: 'auth-code',
    });

    const handleSignOut = () => {
        trackEvent('User', 'Sign Out', 'User Signed Out');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        setIsLoggedIn(false);
        setUserCredits(0);
    }

  return (
      <>
          <Helmet>
              <title>Career Genius | AI Resume and Cover Letter Builder</title>
              <meta name="description" content="Build professional resumes and cover letters with our AI-powered resume and cover letter builder." />
              <meta name="keywords" content="AI Resume Builder, AI Cover Letter Builder, Resume Generator, Cover Letter Generator, Automated Resume Builder, Automated Cover Letter Builder, Online Resume Builder, Online Cover Letter Builder, Professional Resume Builder, Professional Cover Letter Builder, Easy Resume Builder, Easy Cover Letter Builder, AI-Powered Resume Builder, AI-Powered Cover Letter Builder, Resume Writing Service, Cover Letter Writing Service" />

              <meta property="og:type" content="website"/>
              <meta property="og:title" content="Career Genius | AI Resume and Cover Letter Builder" />
              <meta property="og:description" content="Build professional resumes and cover letters with our AI-powered resume and cover letter builder." />
              <meta property="og:image" content="https://careergenius.app/logo.png" />
              <meta property="og:url" content="https://careergenius.app/" />

              <meta name="twitter:card" content="https://careergenius.app/logo.png" />
              <meta name="twitter:title" content="Career Genius | AI Resume and Cover Letter Builder" />
              <meta name="twitter:description" content="Build professional resumes and cover letters with our AI-powered resume and cover letter builder." />
              <meta name="twitter:image" content="https://careergenius.app/logo.png" />
              <meta name="twitter:url" content="https://careergenius.app/" />

          </Helmet>
          <Header isLoggedIn={isLoggedIn} onSignIn={handleSignIn} onSignOut={handleSignOut} userCredits={userCredits} userProfile={userProfile} />
          <Routes>
              <Route exact path="/" element={<HomePage isLoggedIn={isLoggedIn} onSignIn={handleSignIn} />} />
              <Route exact path="/payment-completed" element={<ThankYouPage/>} />
              <Route path="/new" element={<PrivateRoute element={<NewResume fetchUserCredits={fetchUserCredits} />}></PrivateRoute>} />
              <Route exact path="/terms-of-services" element={<ToS/>} />
              <Route exact path="/privacy-policy" element={<PrivacyPolicy/>} />
              <Route path="/applications" element={<PrivateRoute element={<Applications />}></PrivateRoute>} />
              <Route path="/application/:id" element={<PrivateRoute element={<ApplicationDetail />}></PrivateRoute>} />
          </Routes>
          <Footer />
      </>
  );
}

export default App;