import React from 'react';
import '../assets/Pricing.css';
import {FaArrowRight} from "react-icons/fa";
import axiosInstance from "../utils/axios";

const PricingSection = ({isLoggedIn, onSignIn}) => {
    const topUp = async () => {
        const response = await axiosInstance.post("/payment/checkout");
        window.location.href = response.data.redirectUrl;
    };

    return (
        <section className="pricing" id="pricing">
            <div className="container">
                <div className="pricing-content">
                    <h2 className="pricing-title">Pricing</h2>
                    <h3 className="pricing-intro">Top up your credits as needed — no subscriptions or hidden fees. Pay only for the services you use.</h3>
                    <div className="pricing-details">
                        <div className="pricing-item">
                            <h3>Resume Creation</h3>
                            <p>Cost: 2 credits</p>
                        </div>
                        <div className="pricing-item">
                            <h3>Cover Letter Creation</h3>
                            <p>Cost: 1 credit</p>
                        </div>
                    </div>
                    <div className="button-container">
                        {!isLoggedIn ? (
                            <button className="cta-button" onClick={onSignIn}>Get Your Free Credits <FaArrowRight/></button>
                        ) : (
                            <button className="cta-top-up-button" onClick={topUp}>Add Credits Now <FaArrowRight/></button>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default PricingSection;
