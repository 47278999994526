import React, {useEffect} from 'react';
import {Link, useSearchParams} from 'react-router-dom';
import '../assets/ThankYou.css';
import axiosInstance from "../utils/axios";

const ThankYouPage = () => {
    let [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const completeTransaction = () => {
            axiosInstance.post("/payment/completed", {
                refno: searchParams.get('refno'),
                orderRef: searchParams.get('order-ext-ref'),
                signature: searchParams.get('signature')
            });
        }

        completeTransaction();
    }, []);

    return (
        <div className="thank-you-page">
            <h1>Thank You for Your Payment!</h1>
            <p>Your payment has been successfully processed.</p>
            <p>Thank you for choosing our service.</p>
            <p>If you have any questions or need further assistance, please feel free to <Link to="/contact">contact us</Link>.</p>
            <p><Link to="/">Return to Home Page</Link></p>
        </div>
    );
};

export default ThankYouPage;