import React, { useState } from 'react';
import '../assets/FAQ.css';
import {trackEvent} from "../utils/analytics";

const FAQSection = () => {
    // State to manage which question is currently open
    const [openQuestion, setOpenQuestion] = useState(null);

    // Function to toggle the open state of a question
    const toggleQuestion = (index) => {
        trackEvent('User', 'ToggleFAQ', 'FAQ Question viewed');
        setOpenQuestion(openQuestion === index ? null : index);
    };

    // FAQ data - array of objects containing questions and answers
    const faqData = [
        {
            question: "How do I purchase credits?",
            answer: "You can purchase credits by clicking the 'Top-Up' button at the top right."
        },
        {
            question: "How long do my credits last?",
            answer: "Your credits never expire! You can use them at your own pace without any time constraints."
        },
        {
            question: "Can used credits be refunded?",
            answer: "We understand that AI-generated results may occasionally be irrelevant. In such cases, use the 'Ask Refund' button on your application detail page. We'll promptly review your request and refund your credits."
        }
        // Add more FAQ items as needed
    ];

    return (
        <section className="faq" id="faq">
            <div className="container">
                <h2 className="faq-title">Frequently Asked Questions</h2>
                <div className="faq-list">
                    {faqData.map((faq, index) => (
                        <div className="faq-item" key={index}>
                            <button className={`faq-question ${openQuestion === index ? 'open' : ''}`} onClick={() => toggleQuestion(index)}>
                                {faq.question}
                            </button>
                            <div className={`faq-answer ${openQuestion === index ? 'open' : ''}`}>
                                {faq.answer}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default FAQSection;
