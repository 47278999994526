import React from 'react';
import '../assets/Footer.css';
import logo from "../assets/images/logo.png";

const Footer = () => {
    return (
        <footer className="footer">
            <div className="container">
                <div className="footer-content">
                    <div className="footer-left">
                        <img src={logo} alt="Logo" className="footer-logo" />
                        <p className="footer-description">Create Your Resume and Cover Letter with AI</p>
                        <p className="footer-copyright">&copy; 2024 - All rights reserved.</p>
                    </div>
                    <div className="footer-mid">
                        <div className="legal-links">
                            <a href="/terms-of-services">Terms of Services</a>
                            <a href="/privacy-policy">Privacy Policy</a>
                        </div>
                    </div>
                    <div className="footer-right">
                        <div className="contact">
                            Contact for any inquiries <a href="mailto:help@careergenius.app">help@careergenius.app</a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
